<template lang="pug">
  .synced-item.d-flex
    .title.m-t-3
      | {{ syncedItemTitle }}
    .sources-items.w-100
      .item-value.d-flex(
        v-for="(otaName, otaId) in translatedOtaNamesMapping"
        :key="otaId"
      )
        .ota-name {{ otaName }}
        .sources-item-name {{ grouppedSourcesItemNamesByOtaId[otaId] }}
</template>

<script>
  // misc
  import { mapGetters } from "vuex"
  import { transform } from "lodash-es"

  export default {
    props: {
      syncedItemTitle: {
        type: String,
        default: ""
      },
      sourcesItems: {
        type: Array,
        default: () => new Array()
      }
    },

    computed: {
      ...mapGetters(["translatedOtaNamesMapping"]),

      grouppedSourcesItemNamesByOtaId({ sourcesItems }) {
        return transform(sourcesItems, (mapping, { name, ota_id }) => (mapping[ota_id] = name), {})
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .title
    color: $icon-color
    font-size: 0.8rem
    font-weight: 400
    width: 150px

  .item-value
    border-bottom: 1px solid $default-purple-light-line
    color: $default-black
    font-size: 0.8rem
    font-weight: 400
    padding: 5px 0


    &:last-child
      border: 0

    .ota-name
      width: 80px
</style>
